import { Routes } from '@angular/router';
import { RoleGuardService as RoleGuard } from '../core/services/role-guard.service';
import { LayoutComponent } from '../layout/layout.component';

import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { RecoverComponent } from './pages/recover/recover.component';
import { LockComponent } from './pages/lock/lock.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { Error404Component } from './pages/error404/error404.component';
import { Error500Component } from './pages/error500/error500.component';
import { ForgotComponent } from './pages/forgot/forgot.component';
import { SSOComponent } from './pages/sso/sso.component';
import { SSOErrorComponent } from './pages/sso/sso-error.component';

export const routes: Routes = [

    {
        path: '',
        component: LayoutComponent,
        children: [
            { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
            { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule)},
            { path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule) },
            { path: 'staging', loadChildren: () => import('./staging/staging.module').then(m => m.StagingModule) },
            { path: 'units', loadChildren: () => import('./units/units.module').then(m => m.UnitsModule) },
            { path: 'profiles', loadChildren: () => import('./profiles/profiles.module').then(m => m.ProfilesModule) },
            { path: 'profilessearch', loadChildren: () => import('./profiles/profilessearch.module').then(m => m.ProfilesSearchModule) },
            { path: 'enrollment', loadChildren: () => import('./profiles/enrollment.module').then(m => m.EnrollmentModule) },
            { path: 'assignments', loadChildren: () => import('./profiles/assignments.module').then(m => m.AssignmentsModule) },
            { path: 'distributions', loadChildren: () => import('./profiles/distributions.module').then(m => m.DistributionsModule) },
            { path: 'os', loadChildren: () => import('./os/os.module').then(m => m.OsModule) },
            { path: 'agent', loadChildren: () => import('./agents/agents.module').then(m => m.AgentModule) },
            { path: 'files', loadChildren: () => import('./files/files.module').then(m => m.FilesModule) },
            { path: 'userprofile', loadChildren: () => import('./users/users.module').then(m => m.UsersModule) },
            { path: 'user', loadChildren: () => import('./users/users.module').then(m => m.UsersModule), data: { roles: ['ADMIN','SUPERADMIN']}, canActivate: [RoleGuard] },
            { path: 'ae', loadChildren: () => import('./ae/ae.module').then(m => m.AeModule) },
            { path: 'dispensing', loadChildren: () => import('./dispensing/dispensing.module').then(m => m.DispensingModule) },
            { path: 'carts', loadChildren: () => import('./carts/carts.module').then(m => m.CartsModule) },
        ]
    },

    // Not lazy-loaded routes
    { path: 'login', component: LoginComponent },
    { path: 'login/:id', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'forgot', component: ForgotComponent },
    { path: 'recover/:token', component: RecoverComponent },
    { path: 'renew/:token', component: RecoverComponent },
    { path: 'lock', component: LockComponent },
    { path: 'maintenance', component: MaintenanceComponent },
    { path: '404', component: Error404Component },
    { path: '500', component: Error500Component },
    { path: 'sso', component: SSOComponent },
    { path: 'sso-error', component: SSOErrorComponent },

    // Not found
    { path: '**', redirectTo: '404' }

];
